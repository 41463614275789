@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-white-half {
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, transparent 50%);
}

body {
  width: 100%;
}

.text-wrapper p {
  margin-top: 16px;
}
